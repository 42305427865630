<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>学校课程订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">课程信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开通类型" prop="openType">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.openType"
              >
                <el-option
                  v-for="item in p_open_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 学校列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">开通信息</span
          >
        </el-col>
        <el-col :span="15">
          <div class="div_algin_right">
            <el-button type="primary" @click="shoueditInfoDialogClicked"
              >开通课程</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="学校名称" prop="schoolName"></el-table-column>
        <!-- <el-table-column label="开通用户" prop="userName"></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column> -->
        <el-table-column label="课程名称" prop="courseName"></el-table-column>
        <el-table-column label="课程类型" prop="courseType">
          <template scope="scope">
            <el-tag type="success" size="small" v-if="scope.row.courseType === '1'">
              图文类</el-tag
            >
            <el-tag type="danger" size="small" v-else> 视频类</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="课程价格类型" prop="coursePay">
          <template scope="scope">
            <el-tag type="success" size="small" v-if="scope.row.coursePay === '0'">
              免费</el-tag
            >
            <el-tag type="danger" size="small" v-else> 付费</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="beginTime"></el-table-column>
        <el-table-column label="结束时间" prop="endTime"></el-table-column>
        <!-- <el-table-column label="创建时间" prop="createTime"></el-table-column> -->
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import {
  queryProductSchoolList
} from '@/http/api'
export default {
  data () {
    return {
      dataList: [],
      total: 0,
      queryForm: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        season: this.$chnEngStatusCode.defaultSeason,
        pageNum: 1,
        pageSize: 10,
        openType: '',
        phone: '',
        userName: ''
      },
      dict_group: this.$userInfo.dict_group(),
      dict_courseType: this.$userInfo.dict_courseType(),
      dict_season: this.$userInfo
        .getDataList(this, 't_season')
        .then((value) => {
          this.dict_season = value
        }),
      p_open_type: this.$userInfo
        .getDataList(this, 'p_open_type')
        .then((value) => {
          this.p_open_type = value
        }),
      p_word_product_type: this.$userInfo
        .getDataList(this, 'p_word_product_type')
        .then((value) => {
          this.p_word_product_type = value
        })
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryProductSchoolList(this.queryForm)
        .then((res) => {
          console.log('queryOpenProductList', res)
          this.dataList = res.data.list
          this.total = res.data.total
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    openTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    productTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_word_product_type) {
        if (Object.hasOwnProperty.call(this.p_word_product_type, key)) {
          const element = this.p_word_product_type[key]
          if (element.dictValue === data.productType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.openLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 开通课程点击
    shoueditInfoDialogClicked () {
      this.$router.push(
        {
          path: '/product_Management/course_open_management',
          query: {
            openTurnType: 'school'
          }
        }
      )
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    }
  }
}
</script>

    <style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
